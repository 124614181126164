import { createStore } from 'vuex'


export default createStore({
  //State 是存储应用程序级别的状态数据的地方。它类似于组件中的 data 对象，但是它可以被多个组件访问。
  state: {

  },
  //Getters 用于从 State 中派生出一些衍生数据，类似于计算属性。
  getters: {
  },
  //Mutations 用于修改 State 中的数据。
  mutations: {
  },
  // Actions 用于触发 Mutations 来间接修改 State。它们可以包含异步操作，例如网络请求。
  actions: {
  },
  //Vuex 允许您将 State、Mutations、Actions 和 Getters 分割成模块，以便更好地组织和维护代码。
  modules: {
    
  }
})
