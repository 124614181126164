<!-- 主頁面 -->
<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <TopNavbar2 class="sticky-header"></TopNavbar2>
      </el-header>
      <el-main>
        <Main><router-view /></Main>
      </el-main>

      <el-footer>
        <contactUs class="sticky-footer"></contactUs>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import TopNavbar2 from "@/components/TopNavbar2.vue";
import contactUs from "@/components/main/portal/contactUs.vue";

export default {
  components: {
    TopNavbar2,
    contactUs,
  },

  created() {},
  mounted() {},
};
</script>
<style scoped>
.el-container{
  min-height: 100vh;
}
.sticky-header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 1000; /* 保持最顶层 */
}

.el-main,
.el-footer {
  padding-left: 0px !important;
  padding-right: 0px !important; /* 取消主頁面內部的 padding */
}

.sticky-footer {
  position: -webkit-sticky; /* Safari */
  position: sticky;

  background-color: rgb(255, 255, 255) (255, 255, 0);
  width: 100%;
  height: auto;
  padding: 20px 0px;
  left: 0;
  bottom: 0;
  z-index: 1000; /* 保持最顶层 */
}
</style>
